<template>
  <v-row no-gutters>
    <!-- template 1-->
    <v-card v-if="currentTemplate === 'template-1'" flat>
      <v-card-text class="px-0 pt-0 pb-2 text-center">
        <v-container class="pa-0">
          <v-row no-gutters>
            <v-col
              cols="12"
              v-for="(item, i) in template"
              :key="`${item.type}-${i}`"
              class="pa-0"
            >
              <v-menu
                right
                :open-on-hover="item.openOnHover"
                :open-on-click="item.openOnClick"
                :open-delay="10"
                :nudge-right="10"
                :nudge-top="item.nudgeTopDropdown"
                :max-width="35"
                offset-x
                :close-delay="item.openOnHover ? 100 : 0"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-hover v-slot="{ hover }">
                    <div>
                      <v-card
                        :class="[{'pa-5' : !logo.file},`mx-auto text-center subtitle-1 font-weight-semibold black--text`]"
                        v-if="item.type === 'logo' && !logo.hiddenLogo"
                        :color="logo.color"
                        v-bind="attrs"
                        v-on="on"
                        rounded="0"
                        :width="140"
                        flat
                        :style="!logo.file ? 'border: 1px solid #010101' : ''"
                      >
                      <img v-if="logo.file" :src="logo.file" :width="100" />
                      <span v-else>LOGO</span>
                      </v-card>
                      <v-card
                        class="my-5 rounded-0"
                        v-if="item.type === 'image'"
                        :style="!image.hiddenBorder ? `border: ${image.borderWidth}px solid ${image.borderColor}` : ''"
                        v-bind="attrs"
                        v-on="on"
                        flat
                      >
                        <input :ref="item.id" type="file" accept="image/jpeg, image/png" @change="getImagePreview($event, i)" style="display:none">
                        <v-img
                          :src="item.src ? item.src : require(`@/assets/product-outbound/templates/${['EditTemplate', 'TemplatePreview'].includes($route.name) ? 'imag1.png' : 'template-img.svg'}`)"
                          min-width="100%"
                          :height="230"
                        >
                        <v-container :class="{'secondary' : hover}" fill-height style="opacity: .3" />
                        </v-img>
                        <v-btn v-if="hover" absolute @click="$refs[item.id][0].click()" color="primary" style="bottom: 15px; right: 15px;"><v-icon class="mr-2" size="18">mdi-image</v-icon>Cambiar imagen</v-btn>
                      </v-card>

                      <v-card class="my-5 rounded-0" v-if="item.type === 'title'" v-bind="attrs" v-on="on" flat @click="item.isEdit=true" @blur="item.isEdit=false">
                        <v-col cols="9" class="mx-auto pa-0">
                          <span :class="`[d-block font-weight-semibold subtitle-1`" v-if="!item.isEdit" :style="`color: ${text.fontColorTitle}`">{{ item.name }}</span>
                          <v-text-field class="input-template" v-else v-model="item.name" @blur="item.isEdit=false" outlined single-line dense hide-details />
                        </v-col>
                      </v-card>

                      <!-- <v-col class="py-2" v-if="item.type === 'subtitle'">
                        <v-card class="pa-0 rounded-0" v-bind="attrs" v-on="on" flat @click="isActivetitle=true">
                          <span class="d-block font-weight-semibold subtitle-1 black--text">Añade un encabezado atractivo</span>
                        </v-card>
                      </v-col> -->

                      <v-card class="my-4" v-if="item.type === 'paragraph'" v-bind="attrs" v-on="on" flat @click="item.isEdit=true" @blur="item.isEdit=false">
                        <p class="mx-15 subtitle-2" v-if="!item.isEdit" :style="`color: ${text.fontColorParagraph}`">
                          {{ item.name }}
                        </p>
                        <v-col cols="11" class="mx-auto" v-else>
                          <v-textarea class="input-template" v-model="item.name" @blur="item.isEdit=false" outlined dense hide-details :min-height="30"/>
                        </v-col>
                      </v-card>

                        <v-card
                          class="my-5 mx-auto"
                          v-if="item.type === 'btn'"
                          v-bind="attrs"
                          v-on="on"
                          flat
                          width="140"
                          :outlined="item.isEdit ? true : false"
                          @click="item.isEdit=true"
                          @blur.stop="item.isEdit=false"
                        >
                          <v-btn
                            :color="!btn.hiddenBackgroundColor ? btn.backgroundColor : 'bgSearch'"
                            :width="120"
                            :height="32"
                            :style="!btn.hiddenBorder ? `border: ${btn.borderWidth}px solid ${btn.borderColor} !important` : ''"
                          >
                            <span v-if="!item.isEdit" :style="`color: ${btn.fontColor}`">{{item.name}}</span>
                            <v-sheet :width="140" v-else>
                              <v-text-field class="input-template input-template-btn" v-model="item.name" :background-color="!btn.hiddenBackgroundColor ? btn.backgroundColor : 'bgSearch'" :color="`color: ${btn.fontColor}`" @blur="item.isEdit=false" outlined single-line  hide-details />
                            </v-sheet>
                          </v-btn>
                        </v-card>

                        <v-card
                          class="my-5 rounded-0"
                          v-if="item.type === 'image2'"
                          :style="!image.hiddenBorder ? `border: ${image.borderWidth}px solid ${image.borderColor}` : ''"
                          v-bind="attrs"
                          v-on="on"
                          flat
                        >
                          <input :ref="item.id" type="file" accept="image/jpeg, image/png" @change="getImagePreview($event, i)" style="display:none">
                          <v-img
                            :src="item.src ? item.src : require(`@/assets/product-outbound/templates/${['EditTemplate', 'TemplatePreview'].includes($route.name) ? 'imag2.png' : 'template-img.svg'}`)"
                            min-width="100%"
                            :height="230"
                          >
                          <v-container :class="{'secondary' : hover}" fill-height style="opacity: .3" />
                          </v-img>
                          <v-btn v-if="hover" absolute @click="$refs[item.id][0].click()" color="primary" style="bottom: 15px; right: 15px;"><v-icon class="mr-2" size="18">mdi-image</v-icon>Cambiar imagen</v-btn>
                      </v-card>
                    </div>
                  </v-hover>
                </template>
                <v-list class="pa-0 bgSearch">
                  <v-list-item @click="moveUp(item, i)" :disabled="item.disabledUp">
                    <v-list-item-title class="d-flex justify-center">
                      <v-btn text :disabled="item.disabledUp"><v-icon color="secondary" :size="18">mdi-arrow-up</v-icon></v-btn>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="moveDown(item, i)" :disabled="item.disabledDown">
                    <v-list-item-title class="d-flex justify-center">
                      <v-btn text :disabled="item.disabledDown"><v-icon color="secondary" :size="18">mdi-arrow-down</v-icon></v-btn>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="cloneItem(item, i)">
                    <v-list-item-title class="d-flex justify-center">
                      <v-btn text><v-icon color="secondary" :size="18">mdi-content-copy</v-icon></v-btn>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title @click="deleteItem(item)" class="d-flex justify-center">
                      <v-btn text><v-icon color="secondary" :size="18">mdi-delete-outline</v-icon></v-btn>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </v-container>
        <v-col cols="11" class="mx-auto mt-3 pa-0">
          <v-divider class="black" />
          <span class="d-block body-1 black--text mt-5">¿Quieres agregar un pie de página aquí? Puedes elegir qué información mostrar y personalizarla.</span>
        </v-col>
      </v-card-text>
    </v-card>
    <!-- end template 1-->

     <!-- template 2-->
     <v-card v-if="currentTemplate === 'template-2'" flat>
      <v-card-text class="px-0 pt-0 pb-2 text-center">
        <v-container class="pa-0">
          <v-row no-gutters>
            <v-col
              cols="12"
              v-for="(item, i) in template"
              :key="`${item.type}-${i}`"
              class="pa-0"
            >
              <v-menu
                right
                :open-on-hover="item.openOnHover"
                :open-on-click="item.openOnClick"
                :open-delay="10"
                :nudge-right="10"
                :nudge-top="item.nudgeTopDropdown"
                :max-width="35"
                offset-x
                :close-delay="item.openOnHover ? 100 : 0"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-hover v-slot="{ hover }">
                    <div>
                      <v-card
                        :class="[{'pa-5' : !logo.file},`mx-auto text-center subtitle-1 font-weight-semibold black--text`]"
                        v-if="item.type === 'logo' && !logo.hiddenLogo"
                        :color="logo.color"
                        :elevation="hover ? 3 : 0"
                        v-bind="attrs"
                        v-on="on"
                        rounded="0"
                        :width="140"
                        :style="!logo.file ? 'border: 1px solid #010101' : ''"
                      >
                      <img v-if="logo.file" :src="logo.file" :height="80" />
                      <span v-else>LOGO</span>
                      </v-card>

                      <v-card
                        class="my-5 rounded-0"
                        v-if="item.type === 'image'"
                        :style="!image.hiddenBorder ? `border: ${image.borderWidth}px solid ${image.borderColor}` : ''"
                        v-bind="attrs"
                        v-on="on"
                        flat
                      >
                        <input :ref="item.id" type="file" accept="image/jpeg, image/png" @change="getImagePreview($event, i)" style="display:none">
                        <v-img
                          :src="item.src ? item.src : require('@/assets/product-outbound/templates/template-img.svg')"
                          min-width="100%"
                          :height="230"
                        >
                        <v-container :class="{'secondary' : hover}" fill-height style="opacity: .3" />
                        </v-img>
                        <v-btn v-if="hover" absolute @click="$refs.file[0].click()" color="primary" style="bottom: 15px; right: 15px;"><v-icon class="mr-2" size="18">mdi-image</v-icon>Cambiar imagen</v-btn>
                      </v-card>

                      <v-card class="mt-5 mb-2 rounded-0" v-if="item.type === 'title'" v-bind="attrs" v-on="on" flat @click="item.isEdit=true" @blur="item.isEdit=false">
                        <span :class="`[d-block font-weight-semibold subtitle-1`" v-if="!item.isEdit" :style="`color: ${text.fontColorTitle}`">{{ item.name }}</span>
                        <v-col cols="11" class="mx-auto" v-else>
                          <v-text-field class="input-template" v-model="item.name" @blur="item.isEdit=false" outlined single-line dense hide-details />
                        </v-col>
                      </v-card>

                      <!-- <v-col class="py-2" v-if="item.type === 'subtitle'">
                        <v-card class="pa-0 rounded-0" v-bind="attrs" v-on="on" flat @click="isActivetitle=true">
                          <span class="d-block font-weight-semibold subtitle-1 black--text">Añade un encabezado atractivo</span>
                        </v-card>
                      </v-col> -->

                      <v-card class="my-4" v-if="item.type === 'paragraph'" v-bind="attrs" v-on="on" flat @click="item.isEdit=true" @blur="item.isEdit=false">
                        <p class="mx-15 subtitle-2" v-if="!item.isEdit" :style="`color: ${text.fontColorParagraph}`">
                          {{ item.name }}
                        </p>
                        <v-col cols="11" class="mx-auto" v-else>
                          <v-textarea class="input-template" v-model="item.name" @blur="item.isEdit=false" outlined dense hide-details :min-height="30"/>
                        </v-col>
                      </v-card>

                      <v-card
                        class="my-5 rounded-0 transparent"
                        v-if="item.type === 'group'"
                        v-bind="attrs"
                        v-on="on"
                        flat
                      >
                        <v-row>
                          <v-col cols="6" class="py-0" v-for="(children, j) in item.children" :key="children.id">
                            <v-card
                              class="my-5 rounded-0 transparent"
                              v-if="children.type === 'image2'"
                              :style="!image.hiddenBorder ? `border: ${image.borderWidth}px solid ${image.borderColor}` : ''"
                              flat
                            >
                              <input :ref="`${children.id}-${i}`" type="file" accept="image/jpeg, image/png" @change="getImagePreviewChildren($event, i, j)" style="display:none">
                              <v-img
                                :src="children.src ? children.src : require('@/assets/product-outbound/templates/template-img.svg')"
                                min-width="100%"
                                :height="180"
                              >
                              <v-container :class="{'secondary' : hover}" fill-height style="opacity: .3" />
                              </v-img>
                              <v-btn v-if="hover" absolute @click="$refs[`${children.id}-${i}`][0].click()" color="primary" style="bottom: 15px; right: 15px;"><v-icon class="mr-2" size="18">mdi-image</v-icon>Cambiar imagen</v-btn>
                            </v-card>
                            <v-card class="my-0 transparent" v-if="children.type === 'paragraph'" flat @click="children.isEdit=true" @blur="children.isEdit=false">
                              <p class="mx-4 subtitle-2" v-if="!children.isEdit" :style="`color: ${text.fontColorParagraph}`">
                                {{ children.name }}
                              </p>
                              <v-col cols="11" class="mx-auto" v-else>
                                <v-textarea class="input-template" v-model="children.name" @blur="children.isEdit=false" outlined dense hide-details :min-height="30"/>
                              </v-col>
                            </v-card>
                            <v-card
                              class="mx-auto"
                              v-if="children.type === 'btn'"
                              v-bind="attrs"
                              v-on="on"
                              flat
                              width="140"
                              :outlined="children.isEdit ? true : false"
                              @click="children.isEdit=true"
                              @blur.stop="children.isEdit=false"
                            >
                              <v-btn
                                :color="!btn.hiddenBackgroundColor ? btn.backgroundColor : 'bgSearch'"
                                :width="120"
                                :height="32"
                                :style="!btn.hiddenBorder ? `border: ${btn.borderWidth}px solid ${btn.borderColor} !important` : ''"
                              >
                                <span v-if="!children.isEdit" :style="`color: ${btn.fontColor}`">{{children.name}}</span>
                                <v-sheet :width="140" v-else>
                                  <v-text-field class="input-template input-template-btn" v-model="children.name" :background-color="!btn.hiddenBackgroundColor ? btn.backgroundColor : 'bgSearch'" :color="`color: ${btn.fontColor}`" @blur="children.isEdit=false" outlined single-line  hide-details />
                                </v-sheet>
                              </v-btn>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-card>
                    </div>
                  </v-hover>
                </template>
                <v-list class="pa-0 bgSearch">
                  <v-list-item @click="moveUp(item, i)" :disabled="item.disabledUp">
                    <v-list-item-title class="d-flex justify-center">
                      <v-btn text :disabled="item.disabledUp"><v-icon color="secondary" :size="18">mdi-arrow-up</v-icon></v-btn>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="moveDown(item, i)" :disabled="item.disabledDown">
                    <v-list-item-title class="d-flex justify-center">
                      <v-btn text :disabled="item.disabledDown"><v-icon color="secondary" :size="18">mdi-arrow-down</v-icon></v-btn>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="cloneItem(item, i)">
                    <v-list-item-title class="d-flex justify-center">
                      <v-btn text><v-icon color="secondary" :size="18">mdi-content-copy</v-icon></v-btn>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title @click="deleteItem(item)" class="d-flex justify-center">
                      <v-btn text><v-icon color="secondary" :size="18">mdi-delete-outline</v-icon></v-btn>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </v-container>
        <v-col cols="11" class="mx-auto mt-8 pa-0">
          <v-divider class="black" />
          <span class="d-block body-1 black--text mt-5">¿Quieres agregar un pie de página aquí? Puedes elegir qué información mostrar y personalizarla.</span>
        </v-col>
      </v-card-text>
    </v-card>
    <!-- end template 2-->

     <!-- template 3 -->
     <v-col cols="12" class="pa-0" v-if="currentTemplate === 'template-3'">
      <v-card class="mt-n6" flat>
        <v-card-text class="pa-0 text-center">
          <v-col class="pa-0">
            <v-img class="mr-4" :src="require('@/assets/product-outbound/templates/template-img.svg')" min-width="100%" :height="120" />
            <v-col cols="8" class="mx-auto mt-3">
              <v-card class="pa-5 mb-6 mx-auto text-center subtitle-1 font-weight-semibold black--text" rounded="0" outlined :width="140" style="border: 1px solid #010101">
                LOGO
              </v-card>
              <span class="d-block font-weight-semibold subtitle-1 black--text">Añade un encabezado atractivo</span>
              <p class="mt-6 black--text subtitle-2">
                Comienza a escribir tu mensaje aquí. Cuéntale a tu audiencia lo que quieres que sepan en pocas palabras y anímalos a realizar alguna acción.
              </p>
            </v-col>
          </v-col>
          <v-row class="ma-0" no-guttters>
            <v-col cols="6" class="pr-2 pl-0">
              <v-img class="mr-4" :src="require('@/assets/product-outbound/templates/template-img.svg')" min-width="100%" :height="220" />
            </v-col>
            <v-col cols="6" class="pl-3">
              <v-col class="mx-auto text-left">
                <span class="d-block font-weight-semibold subtitle-1 black--text text-left">Añade un encabezado atractivo</span>
                <p class="mt-5 black--text subtitle-2 text-left">
                  Comienza a escribir tu mensaje aquí. Cuéntale a tu audiencia lo que quieres que sepan en pocas palabras y anímalos a realizar alguna acción.
                </p>
                <v-btn class="mt-1" color="primary" :width="120">Botón</v-btn>
              </v-col>
            </v-col>
          </v-row>
          <v-row class="ma-0" no-guttters>
            <v-col cols="6" class="pt-1 pl-3 px-0">
              <v-col class="mx-auto mt-4 text-left">
                <span class="d-block font-weight-semibold subtitle-1 black--text text-left">Añade un encabezado atractivo</span>
                <p class="mt-5 black--text subtitle-2 text-left">
                  Comienza a escribir tu mensaje aquí. Cuéntale a tu audiencia lo que quieres que sepan en pocas palabras y anímalos a realizar alguna acción.
                </p>
                <v-btn class="mt-1" color="primary" :width="120">Botón</v-btn>
              </v-col>
            </v-col>
            <v-col cols="6" class="mt-3 pr-0">
              <v-img class="mr-4" :src="require('@/assets/product-outbound/templates/template-img.svg')" min-width="100%" :height="220" />
            </v-col>
          </v-row>
          <v-col cols="11" class="mx-auto mt-5">
            <v-divider class="black" />
            <span class="d-block body-1 black--text mt-6">¿Quieres agregar un pie de página aquí? Puedes elegir qué información mostrar y personalizarla.</span>
          </v-col>
        </v-card-text>
      </v-card>
    </v-col>
    <!-- end template 2-->

    <!-- template 4 -->
     <v-col cols="12" class="pa-0" v-if="currentTemplate === 'template-4'">
      <v-card class="mt-n6" flat>
        <v-card-text class="pa-0 text-center">
          <v-col class="pa-0">
            <v-img :src="require('@/assets/product-outbound/templates/template-img.svg')" min-width="100%" :height="180" />
            <v-col class="mx-auto mt-5">
              <v-card class="pa-5 mb-6 mx-auto text-center subtitle-1 font-weight-semibold black--text" rounded="0" outlined :width="140" style="border: 1px solid #010101">
                LOGO
              </v-card>
              <span class="d-block font-weight-semibold subtitle-1 black--text">Añade un encabezado atractivo</span>
              <p class="mt-6 black--text subtitle-2 text-left">
                Comienza tu email saludando al lector. Si lo haces en calidad de individuo, preséntate aquí. Expresa tus mejores deseos y explica el motivo de este email.
              </p>
              <p class="mt-6 black--text subtitle-2 text-left">
                Aquí va el mensaje principal. Es importante mantener un tono claro y amable. Asegúrate de incluir todos los puntos clave y cualquier otra información que tus lectores deban saber. Enfócate en lo mucho que valoras a tus suscriptores y en cómo estás trabajando para crear la mejor experiencia posible para ellos.
              </p>
              <p class="mt-6 black--text subtitle-2 text-left">
                Recuérdale al lector que estás disponible para responder sus preguntas e inquietudes.
              </p>
            </v-col>
          </v-col>
          <v-row class="ma-0" no-guttters>
            <v-col cols="6" class="mt-1 pa-0 pr-2">
              <v-img class="mr-4" :src="require('@/assets/product-outbound/templates/template-img.svg')" min-width="100%" :height="210" />
            </v-col>
            <v-col cols="6" class="pl-2 pt-0">
              <v-col class="mx-auto pt-1 text-left">
                <span class="d-block font-weight-semibold subtitle-1 black--text text-left">Añade un encabezado atractivo</span>
                <p class="mt-5 black--text subtitle-2 text-left">
                  Comienza a escribir tu mensaje aquí. Cuéntale a tu audiencia lo que quieres que sepan en pocas palabras y anímalos a realizar alguna acción.
                </p>
                <v-btn class="mt-2" color="primary" :width="120">Botón</v-btn>
              </v-col>
            </v-col>
          </v-row>
          <v-col cols="10" class="mx-auto">
            <p class="mt-4 black--text subtitle-2">
              Comienza a escribir tu mensaje aquí. Cuéntale a tu audiencia lo que quieres que sepan en pocas palabras y anímalos a realizar alguna acción.
            </p>
          </v-col>
          <v-col cols="11" class="mx-auto">
            <v-divider class="black" />
            <span class="d-block body-1 black--text mt-6">¿Quieres agregar un pie de página aquí? Puedes elegir qué información mostrar y personalizarla.</span>
          </v-col>
        </v-card-text>
      </v-card>
    </v-col>
    <!-- end template 2 -->
  </v-row>
</template>
<script>
import VEmptyState from '@/components/commons/VEmptyState'
import MiniHeader from '@/components/commons/MiniHeader'

export default {
  components: {
    VEmptyState,
    MiniHeader
  },
  props: {
    currentTemplate: {
      type: String,
      default: ''
    },
    logo: {
      type: Object,
      default: () => ({})
    },
    image: {
      type: Object,
      default: () => ({})
    },
    btn: {
      type: Object,
      default: () => ({})
    },
    text: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    isActivetitle: false,
    isActiveBtn: false,
    isActiveDescription: false,
    breadcrumbs: {
     main: 'Plantillas',
     children: [{ text: 'Campañas' }]
   },
   template: [],
   editTemplate: [
    {
      id: 0,
      type: 'logo',
      src: 'blob:http://localhost:8080/9b11f417-cff5-4a6d-8046-fe21f86b8245',
      fixed: true,
      backgroundColor: '',
      openOnHover: false,
      openOnClick: false,
      idEdit: false
    },
    {
      id: 1,
      type: 'image',
      src: null,
      borderColor: '',
      borderWidth: '',
      borderRadius: '',
      noBorder: false,
      openOnHover: true,
      openOnClick: false,
      nudgeTopDropdown: -35,
      disabledUp: false,
      disabledDown: false,
      idEdit: false
    },
    {
      id: 2,
      type: 'title',
      name: 'Solo con los verdaderos precios bajos, llenas así tu carrito ¡Y sin ofertas!',
      fontSize: '',
      fontFamily: '',
      fontColor: '',
      openOnHover: false,
      openOnClick: true,
      nudgeTopDropdown: 60,
      disabledUp: false,
      disabledDown: false,
      idEdit: false
    },
    // {
    //  id: 3,
    //   type: 'subtitle',
    //   name: '',
    //   fontSize: '',
    //   fontFamily: '',
    //   fontColor: '',
    //   openOnHover: true,
    // openOnClick: true,
    // nudgeTopDropdown: 60
    // },
    {
      id: 4,
      type: 'paragraph',
      name: 'Cuando aprovechas los verdaderos precios bajos de plazaVea todos los días y tus compras rebalsan el carrito sin buscar ofertas',
      fontSize: '',
      fontFamily: '',
      fontColor: '',
      openOnHover: false,
      openOnClick: true,
      nudgeTopDropdown: 60,
      disabledUp: false,
      disabledDown: false,
      idEdit: false
    },
    {
      id: 5,
      type: 'btn',
      name: 'Ir a PlazaVea',
      fontFamily: '',
      fontColor: '',
      backgroundColor: '',
      borderColor: '',
      noBorder: false,
      noBackgroundColor: false,
      borderWidth: '',
      borderRadius: '',
      align: '',
      openOnHover: false,
      openOnClick: true,
      nudgeTopDropdown: 60,
      disabledUp: false,
      disabledDown: false,
      idEdit: false
    },
    {
      id: 6,
      type: 'image2',
      src: null,
      borderColor: '',
      borderWidth: '',
      borderRadius: '',
      noBorder: false,
      openOnHover: true,
      openOnClick: false,
      nudgeTopDropdown: -10,
      disabledUp: false,
      disabledDown: false,
      idEdit: false
    },
    {
      id: 7,
      type: 'paragraph',
      name: 'PlazaVea de vuelta al cole con los precios bajos',
      fontSize: '',
      fontFamily: '',
      fontColor: '',
      openOnHover: false,
      openOnClick: true,
      nudgeTopDropdown: 60,
      disabledUp: false,
      disabledDown: false,
      idEdit: false
    }
   ],
   template1: [
    {
      id: 0,
      type: 'logo',
      src: null,
      fixed: true,
      backgroundColor: '',
      openOnHover: false,
      openOnClick: false,
      idEdit: false
    },
    {
      id: 1,
      type: 'image',
      src: null,
      borderColor: '',
      borderWidth: '',
      borderRadius: '',
      noBorder: false,
      openOnHover: true,
      openOnClick: false,
      nudgeTopDropdown: -35,
      disabledUp: false,
      disabledDown: false,
      idEdit: false
    },
    {
      id: 2,
      type: 'title',
      name: 'Añade un encabezado atractivo',
      fontSize: '',
      fontFamily: '',
      fontColor: '',
      openOnHover: false,
      openOnClick: true,
      nudgeTopDropdown: 60,
      disabledUp: false,
      disabledDown: false,
      idEdit: false
    },
    // {
    //  id: 3,
    //   type: 'subtitle',
    //   name: '',
    //   fontSize: '',
    //   fontFamily: '',
    //   fontColor: '',
    //   openOnHover: true,
    // openOnClick: true,
    // nudgeTopDropdown: 60
    // },
    {
      id: 4,
      type: 'paragraph',
      name: 'Comienza a escribir tu mensaje aquí. Cuéntale a tu audiencia lo que quieres que sepan en pocas palabras y anímalos a realizar alguna acción.',
      fontSize: '',
      fontFamily: '',
      fontColor: '',
      openOnHover: false,
      openOnClick: true,
      nudgeTopDropdown: 60,
      disabledUp: false,
      disabledDown: false,
      idEdit: false
    },
    {
      id: 5,
      type: 'btn',
      name: 'Botón',
      fontFamily: '',
      fontColor: '',
      backgroundColor: '',
      borderColor: '',
      noBorder: false,
      noBackgroundColor: false,
      borderWidth: '',
      borderRadius: '',
      align: '',
      openOnHover: false,
      openOnClick: true,
      nudgeTopDropdown: 60,
      disabledUp: false,
      disabledDown: false,
      idEdit: false
    },
    {
      id: 6,
      type: 'image2',
      src: null,
      borderColor: '',
      borderWidth: '',
      borderRadius: '',
      noBorder: false,
      openOnHover: true,
      openOnClick: false,
      nudgeTopDropdown: -10,
      disabledUp: false,
      disabledDown: false,
      idEdit: false
    },
    {
      id: 7,
      type: 'paragraph',
      name: 'Comienza a escribir tu mensaje aquí. Cuéntale a tu audiencia lo que quieres que sepan en pocas palabras y anímalos a realizar alguna acción.',
      fontSize: '',
      fontFamily: '',
      fontColor: '',
      openOnHover: false,
      openOnClick: true,
      nudgeTopDropdown: 60,
      disabledUp: false,
      disabledDown: false,
      idEdit: false
    }
   ],
   template3: [
    {
      id: 0,
      type: 'image',
      src: null,
      borderColor: '',
      borderWidth: '',
      borderRadius: '',
      noBorder: false,
      openOnHover: true,
      openOnClick: false,
      nudgeTopDropdown: -35,
      disabledUp: false,
      disabledDown: false,
      idEdit: false
    },
    {
      id: 1,
      type: 'logo',
      src: null,
      fixed: true,
      backgroundColor: '',
      openOnHover: false,
      openOnClick: false,
      idEdit: false
    },
    {
      id: 2,
      type: 'title',
      name: 'Añade un encabezado atractivo',
      fontSize: '',
      fontFamily: '',
      fontColor: '',
      openOnHover: false,
      openOnClick: true,
      nudgeTopDropdown: 60,
      disabledUp: false,
      disabledDown: false,
      idEdit: false
    },
    // {
    //  id: 3,
    //   type: 'subtitle',
    //   name: '',
    //   fontSize: '',
    //   fontFamily: '',
    //   fontColor: '',
    //   openOnHover: true,
    // openOnClick: true,
    // nudgeTopDropdown: 60
    // },
    {
      id: 4,
      type: 'paragraph',
      name: 'Comienza a escribir tu mensaje aquí. Cuéntale a tu audiencia lo que quieres que sepan en pocas palabras y anímalos a realizar alguna acción.',
      fontSize: '',
      fontFamily: '',
      fontColor: '',
      openOnHover: false,
      openOnClick: true,
      nudgeTopDropdown: 60,
      disabledUp: false,
      disabledDown: false,
      idEdit: false
    },
    {
      id: 5,
      type: 'btn',
      name: 'Botón',
      fontFamily: '',
      fontColor: '',
      backgroundColor: '',
      borderColor: '',
      noBorder: false,
      noBackgroundColor: false,
      borderWidth: '',
      borderRadius: '',
      align: '',
      openOnHover: false,
      openOnClick: true,
      nudgeTopDropdown: 60,
      disabledUp: false,
      disabledDown: false,
      idEdit: false
    },
    {
      id: 6,
      type: 'group',
      noBorder: false,
      openOnHover: true,
      openOnClick: false,
      nudgeTopDropdown: -150,
      disabledUp: false,
      disabledDown: false,
      idEdit: false,
      children: [
          {
          id: 0,
          type: 'image2',
          src: null,
          borderColor: '',
          borderWidth: '',
          borderRadius: '',
          noBorder: false
        },
        {
          id: 1,
          type: 'title',
          name: 'Añade un encabezado atractivo',
          fontSize: '',
          fontFamily: '',
          fontColor: '',
          openOnHover: false,
          openOnClick: true,
          nudgeTopDropdown: 60,
          disabledUp: false,
          disabledDown: false,
          idEdit: false
        },
        {
          id: 2,
          type: 'paragraph',
          name: 'Comienza a escribir tu mensaje aquí. Cuéntale a tu audiencia lo que quieres que sepan en pocas palabras y anímalos a realizar alguna acción.',
          fontSize: '',
          fontFamily: '',
          fontColor: '',
          openOnHover: false,
          openOnClick: true,
          nudgeTopDropdown: 60,
          disabledUp: false,
          disabledDown: false,
          idEdit: false
        },
         {
          id: 3,
          type: 'btn',
          name: 'Botón',
          fontFamily: '',
          fontColor: '',
          backgroundColor: '',
          borderColor: '',
          noBorder: false,
          noBackgroundColor: false,
          borderWidth: '',
          borderRadius: '',
          align: '',
          openOnHover: false,
          openOnClick: true,
          nudgeTopDropdown: 60,
          disabledUp: false,
          disabledDown: false,
          idEdit: false
        }
        // {
        //   id: 2,
        //   type: 'image2',
        //   src: null,
        //   borderColor: '',
        //   borderWidth: '',
        //   borderRadius: '',
        //   noBorder: false
        // },
        // {
        //   id: 4,
        //   type: 'paragraph',
        //   name: 'Comienza a escribir tu mensaje aquí. Cuéntale a tu audiencia lo que quieres que sepan en pocas palabras y anímalos a realizar alguna acción.',
        //   fontSize: '',
        //   fontFamily: '',
        //   fontColor: '',
        //   openOnHover: false,
        //   openOnClick: true,
        //   nudgeTopDropdown: 60,
        //   disabledUp: false,
        //   disabledDown: false,
        //   idEdit: false
        // },
        // {
        //   id: 5,
        //   type: 'btn',
        //   name: 'Botón',
        //   fontFamily: '',
        //   fontColor: '',
        //   backgroundColor: '',
        //   borderColor: '',
        //   noBorder: false,
        //   noBackgroundColor: false,
        //   borderWidth: '',
        //   borderRadius: '',
        //   align: '',
        //   openOnHover: false,
        //   openOnClick: true,
        //   nudgeTopDropdown: 60,
        //   disabledUp: false,
        //   disabledDown: false,
        //   idEdit: false
        // },
        // {
        //   id: 6,
        //   type: 'btn',
        //   name: 'Botón',
        //   fontFamily: '',
        //   fontColor: '',
        //   backgroundColor: '',
        //   borderColor: '',
        //   noBorder: false,
        //   noBackgroundColor: false,
        //   borderWidth: '',
        //   borderRadius: '',
        //   align: '',
        //   openOnHover: false,
        //   openOnClick: true,
        //   nudgeTopDropdown: 60,
        //   disabledUp: false,
        //   disabledDown: false,
        //   idEdit: false
        // }
      ]
    }
   ],
   template2: [
    {
      id: 0,
      type: 'image',
      src: null,
      borderColor: '',
      borderWidth: '',
      borderRadius: '',
      noBorder: false,
      openOnHover: true,
      openOnClick: false,
      nudgeTopDropdown: -35,
      disabledUp: false,
      disabledDown: false,
      idEdit: false
    },
    {
      id: 1,
      type: 'logo',
      src: null,
      fixed: true,
      backgroundColor: '',
      openOnHover: false,
      openOnClick: false,
      idEdit: false
    },
    {
      id: 2,
      type: 'title',
      name: 'Añade un encabezado atractivo',
      fontSize: '',
      fontFamily: '',
      fontColor: '',
      openOnHover: false,
      openOnClick: true,
      nudgeTopDropdown: 60,
      disabledUp: false,
      disabledDown: false,
      idEdit: false
    },
    // {
    //  id: 3,
    //   type: 'subtitle',
    //   name: '',
    //   fontSize: '',
    //   fontFamily: '',
    //   fontColor: '',
    //   openOnHover: true,
    // openOnClick: true,
    // nudgeTopDropdown: 60
    // },
    {
      id: 4,
      type: 'paragraph',
      name: 'Comienza a escribir tu mensaje aquí. Cuéntale a tu audiencia lo que quieres que sepan en pocas palabras y anímalos a realizar alguna acción.',
      fontSize: '',
      fontFamily: '',
      fontColor: '',
      openOnHover: false,
      openOnClick: true,
      nudgeTopDropdown: 60,
      disabledUp: false,
      disabledDown: false,
      idEdit: false
    },
    {
      id: 5,
      type: 'btn',
      name: 'Botón',
      fontFamily: '',
      fontColor: '',
      backgroundColor: '',
      borderColor: '',
      noBorder: false,
      noBackgroundColor: false,
      borderWidth: '',
      borderRadius: '',
      align: '',
      openOnHover: false,
      openOnClick: true,
      nudgeTopDropdown: 60,
      disabledUp: false,
      disabledDown: false,
      idEdit: false
    },
    {
      id: 6,
      type: 'group',
      noBorder: false,
      openOnHover: true,
      openOnClick: false,
      nudgeTopDropdown: -150,
      disabledUp: false,
      disabledDown: false,
      idEdit: false,
      children: [
          {
          id: 0,
          type: 'image2',
          src: null,
          borderColor: '',
          borderWidth: '',
          borderRadius: '',
          noBorder: false
        },
        {
          id: 2,
          type: 'image2',
          src: null,
          borderColor: '',
          borderWidth: '',
          borderRadius: '',
          noBorder: false
        },
        {
          id: 3,
          type: 'paragraph',
          name: 'Comienza a escribir tu mensaje aquí. Cuéntale a tu audiencia lo que quieres que sepan en pocas palabras y anímalos a realizar alguna acción.',
          fontSize: '',
          fontFamily: '',
          fontColor: '',
          openOnHover: false,
          openOnClick: true,
          nudgeTopDropdown: 60,
          disabledUp: false,
          disabledDown: false,
          idEdit: false
        },
        {
          id: 4,
          type: 'paragraph',
          name: 'Comienza a escribir tu mensaje aquí. Cuéntale a tu audiencia lo que quieres que sepan en pocas palabras y anímalos a realizar alguna acción.',
          fontSize: '',
          fontFamily: '',
          fontColor: '',
          openOnHover: false,
          openOnClick: true,
          nudgeTopDropdown: 60,
          disabledUp: false,
          disabledDown: false,
          idEdit: false
        },
        {
          id: 5,
          type: 'btn',
          name: 'Botón',
          fontFamily: '',
          fontColor: '',
          backgroundColor: '',
          borderColor: '',
          noBorder: false,
          noBackgroundColor: false,
          borderWidth: '',
          borderRadius: '',
          align: '',
          openOnHover: false,
          openOnClick: true,
          nudgeTopDropdown: 60,
          disabledUp: false,
          disabledDown: false,
          idEdit: false
        },
        {
          id: 6,
          type: 'btn',
          name: 'Botón',
          fontFamily: '',
          fontColor: '',
          backgroundColor: '',
          borderColor: '',
          noBorder: false,
          noBackgroundColor: false,
          borderWidth: '',
          borderRadius: '',
          align: '',
          openOnHover: false,
          openOnClick: true,
          nudgeTopDropdown: 60,
          disabledUp: false,
          disabledDown: false,
          idEdit: false
        }
      ]
    }
   ]
  }),
  created () {
    if (['EditTemplate', 'TemplatePreview'].includes(this.$route.name)) this.template = this.editTemplate
    else this.template = this[this.currentTemplate.replace('-', '')]

    this.template.forEach((elm, index) => {
      if (index === 1) elm.disabledUp = true
      else if (index === this.template.length - 1) elm.disabledDown = true
    })
  },
  watch: {
    template: {
      handler (val) {
        val.forEach((elm, index) => {
          elm.disabledDown = index === this.template.length - 1
          elm.disabledUp = index === 1
        })
      },
      inmediate: true,
      deep: true
    }
  },
  methods: {
    cloneItem (item = {}, index) {
      this.template.splice(index + 1, 0, {...item, id: this.template.length + 1})
    },
    moveUp (item = {}, i) {
      this.template.forEach((elm, index) => {
        if (!elm?.fixed) {
          if (i === index && (index - 1) > 0) {
            this.template.splice(index, 1)
            this.template.splice(index - 1, 0, item)
          }
        }
      })
    },
    moveDown (item = {}, i) {
      this.template.forEach((elm, index) => {
        if (!elm?.fixed) {
          if (i === index) {
            this.template.splice(index, 1)
            this.template.splice(index + 1, 0, item)
          }
        }
      })
    },
    deleteItem (item = {}) {
      this.template = [...this.template.filter(({id}) => id !== item.id)]
    },
    getImagePreview (e, index) {
      if (e.target?.files?.length) {
        this.template[index].src = URL.createObjectURL(e.target.files[0])
      } else {
        this.template[index].src = ''
      }
    },
    getImagePreviewChildren (e, index, children) {
      if (e.target?.files?.length) {
        this.template[index].children[children].src = URL.createObjectURL(e.target.files[0])
      } else {
        this.template[index].children[children].src = ''
      }
    }
  }
}
</script>